import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Keyframes, animated } from 'react-spring';
import styled from 'styled-components';


const Text =styled(animated.span)`
display: inline-block; 
color: ${props => props.theme.red};
border-bottom: 8px solid ${props => props.theme.red}59;
`;

const Container = Keyframes.Spring({
  show: { to: { opacity: 1, transform: 'translateY(0px)' } },
  hide: { to: { opacity: 0, transform: 'translateY(10px)' } },
})

export default class RotatingText extends Component {
  state = {
    currentText: null,
    show: true,
  }
 
  componentDidMount = () => {
    const { text, interval } = this.props;

    this.setState({
      currentText: text[0]
    })

    this.count = 0;

    this.interval = setInterval(() => {


      this.count++;
      if (this.count === text.length) {          
        this.count = 0;
      }

      this.setState({
        currentText: text[this.count]
      })

        //autoPlay some for specific period of times or
        // Do some stuff you want
      }, interval);

    this.halfInterval = setInterval(() => {
      this.setState({
        show: !this.state.show,
      })
      //autoPlay some for specific period of times or
      // Do some stuff you want
    }, interval / 2);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.halfInterval);

  }
  render() {
    return (
      this.state.currentText ?
      <Container state={this.state.show ? 'show' : 'hide'}>
        {styles => <Text style={styles}>{this.state.currentText}</Text>}
      </Container>
      :
      null
    )
  }
}

RotatingText.propTypes = {
  text: PropTypes.array.isRequired,
  interval: PropTypes.number,
}
RotatingText.defaultProps = {
  interval: 2500,
}