import React from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import OnlineMenu from './OnlineMenu';
import {
  Panel, Para, Head, Sub, Container, Cover,
} from '../../elements';
import Content from '../../components/Content';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import Back from '../../components/Back';
import RotatingText from '../../components/RotatingText';
import AppContainer from '../../components/AppContainer';

const ImagePanel = styled.div`
  position: relative;
  background-color: ${props => (props.color ? props.theme[props.color] : null)};
  min-height: 400px;
  height: 100%;
`;

const Online = ({ data: { contentfulPage: page } }) => {
  return (
    <AppContainer pageTitle={page.title}>
      <Container id="content">
        <Panel>
          <Back />
          <OnlineMenu />
          <Head ariaRole="heading">{page.heading}</Head>
          <Sub>
            {page.subHeading}
            &nbsp;
            <RotatingText text={page.items[0].infoList} />
          </Sub>
          <Content content={page.content.contentfulRichText} />
          <Para>
            <a href="https://www.jewishnews.net.au">jewishnews.net.au</a>
            {' '}
is the most comprehensive
            online news source for Australia’s Jewish community.
          </Para>
        </Panel>
        <ImagePanel color="blue">
          <PreviewCompatibleImage
            imgStyle={{ objectPosition: 'top center' }}
            cover
            imageInfo={page.image || ''}
          />
        </ImagePanel>
        {/*  <ImagePanel color="blue" img={iPhone} /> */}
      </Container>
    </AppContainer>
  );
};

export const onlinePageQuery = graphql`
  query onlinePageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      heading
      subHeading
      items {
        infoList
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;

export default Online;
